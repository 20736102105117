.text-block {
    @apply my-8;
}

.text-block__title {
    @apply mb-5;
}

.text-block {
    .cta {
        @extend .link--cta;

        @apply text-blue;
        @apply mt-2;

        &:after {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='4.5633px' height='7.60549px' version='1.1' viewBox='0 0 4.5633 7.60549'%3E%3Cdefs%3E%3Cpath id='path-n7xwcsb74j-1' d='m0 6.463 1.054 1.142 3.509-3.802L1.054 0 0 1.143l2.455 2.66z'/%3E%3C/defs%3E%3Cg id='Page-1' fill='none' fill-rule='evenodd' stroke='none' stroke-width='1'%3E%3Cmask id='mask-n7xwcsb74j-2' fill='%23fff'%3E%3Cuse xlink:href='%23path-n7xwcsb74j-1'/%3E%3C/mask%3E%3Cuse xlink:href='%23path-n7xwcsb74j-1' id='Path' fill='%23325BE7'/%3E%3C/g%3E%3C/svg%3E");
        }
    }
}

.text-block + .text-block {
    .text-block__content {
        border-top: 1px solid #DDD;

        @apply pt-6;
    } 
}

.content-wrapper--with-sidebar .text-block {
    .max-text-width {
        max-width: none;
    }
}

/*------------------------------------*\
  #TEXT-BLOCK--WITH-IMAGE
\*------------------------------------*/

.text-block--with-image {
    display: flow-root;
}

@include min-width(lg) {
    .text-block--with-image {
        img {
            @apply max-w-sm;
            @apply mb-4;
        }
    }

    .text-block__content--image-on-left {
        img {
            @apply float-left;
            @apply mr-8;
        }
    }
    
    .text-block__content--image-on-right {
        img {
            @apply float-right;
            @apply ml-8;
        }
    }
}

@include max-width(lg) {
    .text-block--with-image {
        img {
            @apply mb-6;
            @apply max-w-sm;
        }
    }
}

@include max-width(xs) {
    .text-block--with-image {
        img {
            max-width: 100%;
        }
    }
}