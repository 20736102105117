.cookies-page__content {
    @apply my-12;
}

.cookies-page__intro {
    @apply my-8;
    @apply text-18;
}

.cookies-page__form {
    p {
        @apply mb-2;
    }

    fieldset {
        @apply mb-6;
    }

    span {
        @apply font-bold;
    }
}