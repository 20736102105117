.people-list-block {
    @apply my-12;
}

.people-list-block__title {
    @apply mb-6;
}

@include max-width(lg) {
    .people-list-block__items {
        @apply grid-cols-2;
    }
}

@include max-width(md) {
    .people-list-block__items {
        @apply grid-cols-1;
    }
}