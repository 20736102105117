body {
    @apply text-black font-relative;

    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
}

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4 {
    @apply font-bold;
}

h1, .h1 {
    @apply text-55;

    @include max-width(md) {
        @apply text-40;
    }
}

h2, .h2 {
    @apply text-40;
    @apply text-navy;

    @include max-width(md) {
        @apply text-30;
    }
}

h3, .h3 {
    @apply text-22;
}

h4, .h4 {
    @apply text-18;
}

.caption {
    @apply text-14;
    @apply font-normal;
    @apply tracking-wide;
}

.eyebrow {
    @apply uppercase;
    @apply text-blue;
    @apply font-bold tracking-wide;
}

.error {
    @apply text-red font-bold text-14;
    @apply block;
    @apply my-1;
    @apply w-full;
}

hr {
    border-top: 1px solid #DDD;
}