.words-for-life-banner {
    @apply relative;
}

.words-for-life-banner__content {
    @apply flex;
}

.words-for-life-banner__shape {
    @apply absolute;
}

.words-for-life-banner__text {
    @apply text-white;
}

.words-for-life-banner__title {
    @apply text-pink;
}

.words-for-life-banner__body {
    @apply font-bold;
    @apply my-4;
}

.words-for-life-banner__image {
    @apply relative z-10;
}

.words-for-life-banner__cta a {
    @extend .link--cta;

    @apply text-white;

    &:after {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='4.5633px' height='7.60549px' version='1.1' viewBox='0 0 4.5633 7.60549'%3E%3Cdefs%3E%3Cpath id='path-n7xwcsb74j-1' d='m0 6.463 1.054 1.142 3.509-3.802L1.054 0 0 1.143l2.455 2.66z'/%3E%3C/defs%3E%3Cg id='Page-1' fill='none' fill-rule='evenodd' stroke='none' stroke-width='1'%3E%3Cmask id='mask-n7xwcsb74j-2' fill='%23fff'%3E%3Cuse xlink:href='%23path-n7xwcsb74j-1'/%3E%3C/mask%3E%3Cuse xlink:href='%23path-n7xwcsb74j-1' id='Path' fill='%23FFFFFF%0A'/%3E%3C/g%3E%3C/svg%3E");
    }
}

@include max-width(lg) {
    .words-for-life-banner {
        @apply overflow-hidden;
        @apply bg-navy;

        @extend .full-width;
        padding-left: rem(25px);
        padding-right: rem(25px);

        &:after {
            content: "";
            @apply absolute;
            @apply bottom-0 left-0;
            @apply w-full h-1/2;
        }
    }

    .words-for-life-banner__content {
        @apply flex-col-reverse gap-20;
        @apply py-8;
    }

    .words-for-life-banner__text {
        @apply relative z-10;
    }

    .words-for-life-banner__shape--desktop {
        @apply hidden;
    }

    .words-for-life-banner__shape--mobile {
        @apply top-0 left-0;
        @apply w-full;
        @apply z-0;

        height: rem(240px);
    }
}

@include max-width(md) {
    .words-for-life-banner__shape--mobile {
        left: rem(-100px);
        width: calc(100% + 120px);
    }
}

@include max-width(xs) {
    .words-for-life-banner__shape--mobile {
        height: rem(320px);
    }
}

@include between-widths(md, lg) {
    .words-for-life-banner {
        padding-left: rem(100px);
        padding-right: rem(100px);
    }
}

@include min-width(lg) {
    .words-for-life-banner__content {
        @apply justify-between gap-4;
        @apply pt-12 pb-8;

        .words-life-banner__image {
            @apply self-end;
        }
    }

    .words-for-life-banner .logo-wfl {
        max-width: rem(280px);
    }

    .words-for-life-banner__image {
        @apply flex flex-col justify-end;
    }

    .words-for-life-banner__shape--mobile {
        @apply hidden;
    }

    .words-for-life-banner__shape--desktop {
        width: 70%;
        top: -1px;
        height: calc(100% + 1px);

        @apply left-0;
    }

    .words-for-life-banner__text {
        max-width: 50%;

        @apply pb-12;
    }

    .content-wrapper--with-sidebar .words-for-life-banner {
        width: calc(100% + 5rem);

        @apply relative;
        @apply pr-20;

        .contained-width {
            @apply relative z-10;
        }

        .words-for-life-banner__background {
            width: 15vw;
            right: 99%;

            @apply h-full;
            @apply absolute top-0;
        }
    }

    .content-wrapper--no-sidebar .words-for-life-banner {
        @apply relative;

        .contained-width {
            @apply relative z-10;
        }

        .words-for-life-banner__text {
            max-width: 45%;
        }

        .words-for-life-banner__background {
            width: 100vw;
            left: -100vw;

            @apply h-full;
            @apply absolute top-0;
        }

        &:after {
            content: "";
            width: 100vw;
            right: -100vw;

            @apply h-full;
            @apply absolute top-0;
            @apply bg-purple;
        }
    }
}