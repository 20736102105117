.logo-list {
    @apply my-8;

    .btn {
        @apply mt-6;
    }
}

.logo-list__title {
    @apply text-navy;
    @apply mb-8;
}

@include min-width(lg) {
    .logo-list {
        @apply my-12;
    }
}

@include between-widths(md, lg) {
    .logo-list__items.grid--4-col {
        @apply grid-cols-2;
    }
}

@include max-width(md) {
    .logo-list__items {
        @apply grid-cols-1;
    }
}