.mobile-only {
    @include min-width(lg) {
        @apply hidden;
    }
}

.desktop-only {
    @include max-width(lg) {
        @apply hidden;
    }
}

.theme-white {
    @apply bg-white;
}

.theme-red {
    @apply bg-red;
}

.theme-green {
    @apply bg-green-mid;
}

.theme-pink {
    @apply bg-pink;
}

.theme-green-lime {
    @apply bg-green-lime;
}

.theme-purple {
    @apply bg-purple;
}

.theme-beige {
    @apply bg-beige;
}

.theme-purple {
    @apply bg-purple;
}

.theme-blue {
    @apply bg-blue;
    @apply text-white;

    .rich-text a {
        @apply text-white;
    }
}

.theme-green-dark {
    @apply bg-green-dark;
    @apply text-white !important;

    .rich-text a {
        @apply text-white;
    }
}

.theme-navy {
    @apply bg-navy;
    @apply text-white !important;

    .rich-text a {
        @apply text-white;
    }
}

.theme-orange {
    @apply bg-orange;
    @apply text-white !important;

    .rich-text a {
        @apply text-white;
    }
}

.theme-amber {
    @apply bg-amber;
    @apply text-white !important;

    .rich-text a {
        @apply text-white;
    }
}

