a {
    @apply underline;

    &:hover, &:focus, &:active {
        @apply no-underline;
    }
}

.reverse-underline {
    @apply no-underline;

    &:hover, &:focus, &:active {
        @apply underline;
    }
}

.link--cta {
    @apply flex gap-2 items-center;
    @apply font-bold;
    @apply tracking-wide;
    @apply text-navy;

    &:after {
        content: "";
        display: block;
        position: relative;
        top: 1px;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='4.5633px' height='7.60549px' version='1.1' viewBox='0 0 4.5633 7.60549'%3E%3Cdefs%3E%3Cpath id='path-n7xwcsb74j-1' d='m0 6.463 1.054 1.142 3.509-3.802L1.054 0 0 1.143l2.455 2.66z'/%3E%3C/defs%3E%3Cg id='Page-1' fill='none' fill-rule='evenodd' stroke='none' stroke-width='1'%3E%3Cmask id='mask-n7xwcsb74j-2' fill='%23fff'%3E%3Cuse xlink:href='%23path-n7xwcsb74j-1'/%3E%3C/mask%3E%3Cuse xlink:href='%23path-n7xwcsb74j-1' id='Path' fill='%231D2D52'/%3E%3C/g%3E%3C/svg%3E");
        height: 8px;
        width: 5px;

        @apply bg-no-repeat;
    }
}