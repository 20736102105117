.panel {
    @apply py-12;
    @apply my-8;
}

.panel__title {
    @apply text-blue;
    @apply mb-4;
}

.panel {
    .cta {
        @extend .link--cta;

        @apply text-blue;
        
        &:after {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='4.5633px' height='7.60549px' version='1.1' viewBox='0 0 4.5633 7.60549'%3E%3Cdefs%3E%3Cpath id='path-n7xwcsb74j-1' d='m0 6.463 1.054 1.142 3.509-3.802L1.054 0 0 1.143l2.455 2.66z'/%3E%3C/defs%3E%3Cg id='Page-1' fill='none' fill-rule='evenodd' stroke='none' stroke-width='1'%3E%3Cmask id='mask-n7xwcsb74j-2' fill='%23fff'%3E%3Cuse xlink:href='%23path-n7xwcsb74j-1'/%3E%3C/mask%3E%3Cuse xlink:href='%23path-n7xwcsb74j-1' id='Path' fill='%23325BE7'/%3E%3C/g%3E%3C/svg%3E");
        }
    }
}

.panel__body {
    @apply mb-3;
}

.panel__text {
    @apply flex flex-col items-start justify-center;
}

.panel.theme-blue {
    .panel__title {
        @apply text-white;
    }

    .cta {
        @extend .link--cta;

        @apply text-white;
        
        &:after {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='4.5633px' height='7.60549px' version='1.1' viewBox='0 0 4.5633 7.60549'%3E%3Cdefs%3E%3Cpath id='path-n7xwcsb74j-1' d='m0 6.463 1.054 1.142 3.509-3.802L1.054 0 0 1.143l2.455 2.66z'/%3E%3C/defs%3E%3Cg id='Page-1' fill='none' fill-rule='evenodd' stroke='none' stroke-width='1'%3E%3Cmask id='mask-n7xwcsb74j-2' fill='%23fff'%3E%3Cuse xlink:href='%23path-n7xwcsb74j-1'/%3E%3C/mask%3E%3Cuse xlink:href='%23path-n7xwcsb74j-1' id='Path' fill='%23FFFFFF%0A'/%3E%3C/g%3E%3C/svg%3E");
        }
    }
}

.panel.theme-white {
    @apply py-0;
}

@include max-width(lg) {
    .panel {
        @extend .full-width;
        padding-left: rem(25px);
        padding-right: rem(25px);
        
        @apply py-8;
    }

    .panel__image {
        @apply mt-8;
    }
}

@include between-widths(md, lg) {
    .panel {
        padding-left: rem(100px);
        padding-right: rem(100px);
    }
}

@include min-width(lg) {
    .panel__content {
        @apply grid grid-cols-9 gap-8;
    }

    .panel__text {
        @apply col-span-5;
    }

    .panel__image {
        @apply col-span-4;
    }

    .content-wrapper--with-sidebar .panel {
        width: calc(100% + 5rem);

        @apply relative;
        @apply pr-20;

        .contained-width {
            @apply relative z-10;
        }

        .panel__background {
            width: 15vw;
            right: 99%;

            @apply h-full;
            @apply absolute top-0;
        }
    }

    .content-wrapper--no-sidebar .panel {
        @apply relative;

        .contained-width {
            @apply relative z-10;
        }

        .panel__background {
            width: 200vw;
            left: -100vw;

            @apply h-full;
            @apply absolute top-0;
        }
    }
}