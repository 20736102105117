.donate-block {
    @apply my-12;
}

.donate-block__header {
    @apply mb-6;
}

.donate-block__intro {
    @apply mt-4;
}

.donate-block__item {
    border: 1px solid #E4E4E4;
}

.donate-block__item-title {
    @apply text-blue;
}

.donate-block__item-descriptor {
    @apply mb-4;
}

.donate-block__item-content {
    @apply border-t-4 border-green-mid;
    @apply text-center;
    @apply p-6;
}

@include max-width(lg) {
    .donate-block {
        @apply my-8;
    }

    .donate-block__items {
        @apply grid-cols-1;
    }

    .donate-block__item-body {
        max-width: rem(400px);

        @apply mx-auto;
    }
}