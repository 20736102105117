.article-page {
    .content-wrapper {
        @extend .contained-width;
    }

    .content {
        @apply my-8;
    }
}

.article-page__header {
    @apply mb-6;

    img {
        @apply w-full;
        @apply mt-4;
    }
}

.article-page__title {
    @apply text-navy;
    @apply mt-2 mb-2;
}

.article-page__body-content {
    .btn {
        @apply my-2;
    }
}

.article-page__footer {
    @apply flex justify-between items-center;
    @apply border-y;
    @apply py-4;
    @apply mt-10;

    border-color: #EEEEEE;

    .article-page__top-link {
        @apply flex items-center gap-2;
        @apply font-medium;

        svg {
            @apply rotate-180;
        }

        #Path {
            @apply fill-black;
        }
    }

    .sharing-links {
        @apply flex items-center gap-4;

        h2 {
            @apply text-16 text-black;
        }

        ul {
            @apply flex items-center gap-2;
        }

        a {
            &:hover, &:focus, &:active {
                path {
                    @apply fill-blue;
                }
            }
        }
    }
}

.article-page .content-wrapper--no-sidebar {
    .content {
        @extend .max-text-width;

        @apply mx-auto;
    }
}

@include min-width(lg) {
    .content-wrapper--with-sidebar {
        .article-page__footer,
        .article-page__body-content,
        .article-page__header {
            @apply pr-20;
        }

        .breadcrumbs {
            @apply mr-20;
        }
    }
}

@include max-width(lg) {
    .article-page {
        .content-wrapper {
            max-width: 100%;
        }
    }
}

@include max-width(sm) {
    .article-page__footer {
        @apply flex-col items-start gap-4;

        .sharing-links {
            @apply flex-col items-start gap-2;
        }
    }
}

/*------------------------------------*\
  #RESOURCE-PAGE
\*------------------------------------*/

@include min-width(xl) {
    .article-page__resource-content {
        @apply flex items-start gap-8;
        @apply my-8;
    }
}

@include max-width(xl) {
    .article-page__resource-content {
       img {
        @apply my-6;
       }
    }
}

/*------------------------------------*\
  #LITERACY-SUPPORT-PAGE
\*------------------------------------*/

.article-page__literacy-support {
}

.article-page__literacy-support-subtitle {
    @apply mb-2 mt-8;
}

.article-page__literacy-support-contact {
    li {
        @apply mt-1;
    }
}