.index-page {
    .content-wrapper {
        @extend .contained-width;
    }

    .content {
        @apply my-8;
    }
}

.index-page__header {
    border-bottom: 1px solid #DDD;

    @apply pb-6;
    @apply my-4;

    img {
        @apply mb-6 mt-8;
    }
}

.index-page__header-title {
    @apply mb-4;
}

.index-page__header-subtitle {
    @apply mt-6 mb-3;
    @apply text-black;
}

.index-page__article-list {
    @apply my-8;

    .card:nth-of-type(3n+1) {
        .card__text {
            @apply bg-purple;
        }
    }

    .card:nth-of-type(3n+2) {
        .card__text {
            @apply bg-green-lime;
        }
    }

    .card:nth-of-type(3n+3) {
        .card__text {
            @apply bg-pink;
        }
    }
}

.index-page__rich-text {
    .h3 {
        @apply text-22;
        @apply mt-6;
    }
}

@include min-width(lg) {
    .content-wrapper--with-sidebar {
        .index-page__body-content {
            @apply pr-20;
        }
    }
}