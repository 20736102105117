.table-container {
    @apply max-w-full;
    @apply overflow-x-auto;
    @apply my-6;
}

table {
    @apply w-full;
    @apply border border-grey-mid;
    @apply text-left;
}

table caption {
    @apply mb-4;
    @apply text-left;
}

thead {
    @apply border-b border-grey-mid;
    @apply bg-grey-mid;
}

th {
    @apply font-semibold;
    @apply py-1 px-3;
    @apply border-r border-grey-mid;
}

th[scope="col"]:last-of-type {
    border-right: 0;
}

tr td {
    @apply px-3 py-2;
    @apply border-r border-grey-mid;
}

tr:not(:first-of-type) {
    @apply border-t border-grey-mid;
}