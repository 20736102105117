.donate-page__header {
    @apply mb-8;
}

.donate-page__title {
    @apply mb-3;
}

.donation-form__header {
    @apply text-18;
}

.donation-form__radio-buttons {
    @apply flex gap-6;
    @apply mt-2;

    label {
        @apply inline-block;
    }
}

.donation-form__next-chapter {
    @apply mt-4;

    label {
        @apply inline-block;
    }
}

.donation-form__details {
    > div {
        @apply mb-4;
    }
}

.donation-form__giftaid-text {
    @apply mb-6;

    p {
        @apply mb-2;
    }
}

.donation-form {
    .boolean-container {
        input {
            top: 2px;
        }
    }
}