.quote-block {
    @apply py-8 my-8;
    @apply px-8;

    p {
        @apply text-22;
        @apply italic;
        @apply tracking-wide;
        @apply text-navy;
    }

    cite {
        @apply mt-4;
        @apply block;
    }
}

@include max-width(sm) {
    .quote-block {
        @apply px-4 py-4;

        p {
            @apply text-18;
            @apply tracking-normal;
        }
    }
}