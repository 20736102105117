/*------------------------------------*\
  #RELATIVE NLT
\*------------------------------------*/

@font-face {
    font-family: 'Relative NLT';
    src: url('/static/fonts/relative/RelativeNLT-Italic.woff2') format('woff2'),
         url('/static/fonts/relative/RelativeNLT-Italic.woff') format('woff'),
         url('/static/fonts/relative/RelativeNLT-Italic.ttf') format('ttf');
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: 'Relative NLT';
    src: url('/static/fonts/relative/RelativeNLT-Book.woff2') format('woff2'),
         url('/static/fonts/relative/RelativeNLT-Book.woff') format('woff'),
         url('/static/fonts/relative/RelativeNLT-Book.ttf') format('ttf');
    font-weight: 400;
}

@font-face {
    font-family: 'Relative NLT';
    src: url('/static/fonts/relative/RelativeNLT-Medium.woff2') format('woff2'),
         url('/static/fonts/relative/RelativeNLT-Medium.woff') format('woff'),
         url('/static/fonts/relative/RelativeNLT-Medium.ttf') format('ttf');
    font-weight: 500;
}

@font-face {
    font-family: 'Relative NLT';
    src: url('/static/fonts/relative/RelativeNLT-Bold.woff2') format('woff2'),
         url('/static/fonts/relative/RelativeNLT-Bold.woff') format('woff'),
         url('/static/fonts/relative/RelativeNLT-Bold.ttf') format('ttf');
    font-weight: 700;
}