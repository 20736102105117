.footer {
    @apply bg-green-mid;
    @apply font-medium;
    @apply py-8;
}

.footer__upper {
    @apply border-b border-black border-opacity-20;
    @apply mb-6 pb-6;
}

.footer__home-link {
    @apply flex justify-start;
}

.footer__links {
    @apply flex flex-col gap-2;
}

.footer__buttons {
    @apply flex flex-col gap-4;
}

.footer__header {
    @apply font-black text-black;
    @apply text-16;
    @apply mb-3;
}

.footer__social-links {
    @apply flex gap-2;
    @apply mt-4;

    path {
        @apply transition-colors duration-100;
    }

    a {
        &:hover, &:focus, &:active {
            path {
                @apply fill-blue;
            }
        }
    }
}

.footer__lower {
    @apply text-14;
}

.footer__hactar {
    @apply flex items-center gap-1;
    @apply mt-2;

    svg {
        max-height: 20px;
        
        @apply w-auto;
    }
}

@include min-width(lg) {
    .footer {
        @apply py-12;
    }

    .footer__upper {
        @apply grid grid-cols-12;
    }

    .footer__buttons {
        @apply items-end;
    }

    .footer__lower {
        @apply flex justify-between;
    }

    .footer__charity-info {
        @apply flex flex-col items-end;
        @apply text-right;
        
        max-width: 60%;
    }
}

@include max-width(lg) {
    .footer__buttons {
        @apply mt-6;
        @apply items-start;
    }

    .footer__social-links {
        @apply mb-6;
    }

    .footer__header {
        @apply mt-4;
    }

    .footer__hactar {
        @apply mb-4;
    }
}