.signup-block {
    @apply bg-purple;
    @apply py-16;
}

.signup-block__title {
    @apply text-center;
}

.signup-block__accounts {
    @apply bg-white;
    @apply grid;
    @apply mt-12 mb-8;
}

.signup-block__account {
    @apply p-8;
}

.signup-block__account-header {
    @apply text-center;
}

.signup-block__account-intro {
    @apply my-6;
}

.signup-block__features {
    @apply flex flex-col gap-4;
    @apply mb-8;
}

.signup-block__feature {
    @apply flex gap-2;

    svg {
        @apply flex-shrink-0;
    }
}

.signup-block__feature--unavailable {
    span {
        @apply opacity-60;
    }
}

.signup-block__account--school {
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.08);
}

.signup-block__individual-member {
    @apply text-center;

    .link--cta {
        @apply inline-flex;
        @apply mt-2;
    }
}

@include max-width(lg) {
    .signup-block {
        @apply bg-purple;
        @apply py-10;
    }
}

@include min-width(lg) {
    .signup-block__accounts {
        @apply grid-cols-4;
    }

    .signup-block__account {
        &:first-of-type {
            border-right: 1px solid #DDD;
        }
    }
}

@include between-widths(md, lg) {
    .signup-block__accounts {
        @apply grid-cols-2;
    }

    .signup-block__account {
        &:nth-of-type(2) {
            border-bottom: 1px solid #DDD;
            border-left: 1px solid #DDD;
        }
    }
}

@include max-width(md) {
    .signup-block__accounts {
        @apply grid-cols-1;
    }

    .signup-block__account {

        &:nth-of-type(2) {
            border-top: 1px solid #DDD;
        }
    }
}

.signup-block--upgrade {
    @apply bg-white;
    @apply py-0 mb-8;

    .signup-block__title {
        @apply text-30;
        @apply text-left;
    }

    .signup-block__accounts {
        @apply grid-cols-1;
    }

    .signup-block__account {
        border: none;
        &:nth-of-type(2) {
            border-top: 1px solid #DDD;
        }
    }

    .signup-block__accounts {
        @apply mb-4 mt-6;

        border: 1px solid #DDD;
    }
}

@include min-width(lg) {
    .signup-block--upgrade {
        @apply mr-20;
    }
}