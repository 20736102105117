input[type="text"],
input[type="email"],
input[type="password"],
input[type="number"],
textarea {
    border: 1px solid #DDDDDD;

    @apply rounded-sm;
    @apply p-2;
}

input[type="checkbox"] {
    @apply appearance-none;
    @apply bg-white;
    @apply rounded-sm;
    @apply h-5 w-5;
    @apply relative;
    @apply mr-1;

    top: 5px;
    border: 2px solid #A1A5A9;

    &:before {
        content: '';
        @apply absolute left-0 right-0 top-0 bottom-0;
        @apply m-auto;
        @apply overflow-hidden;
    }

    &:focus {
        @apply outline-2;
        @apply outline-offset-2;
    }
}

input[type=checkbox]:indeterminate:before,
input[type=checkbox][aria-checked=mixed]:before {
  border: 2px solid white;
  height: 0;
  width: 40%;
}

input[type=checkbox]:checked {
    @apply bg-green-mid;
    @apply border-green-mid;
}


input[type=checkbox]:checked:before {
    @apply border-r-2 border-b-2 border-white;
    height: 70%;
    width: 40%;
    transform: rotate(45deg) translateY(-15%) translateX(-10%);
}

input[type="radio"] {
    @apply appearance-none;
    @apply bg-white;
    @apply rounded-full;
    @apply h-5 w-5;
    @apply relative;
    @apply mr-1;

    top: 5px;
    border: 2px solid #A1A5A9;

    &:before {
        content: '';
        @apply absolute left-0 right-0 top-0 bottom-0;
        @apply m-auto;
        @apply overflow-hidden;
    }

    &:focus {
        @apply outline-2;
        @apply outline-offset-2;
    }
}

input[type=radio]:indeterminate:before,
input[type=radio][aria-checked=mixed]:before {
  border: 2px solid white;
  height: 0;
  width: 40%;
}

input[type=radio]:checked {
    @apply bg-green-mid;
    @apply border-green-mid;
}


input[type=radio]:checked:before {
    height: 80%;
    width: 80%;

    @apply border-4 border-white;
    @apply rounded-full;
}

input[type=radio] + label,
input[type=checkbox] + label {
    @apply text-14;
    @apply font-normal;
    @apply tracking-wide;
}

.search-form {
    @apply flex;
    @apply mt-4;

    input {
        @apply rounded-r-none;
        @apply border-r-0;
        @apply font-normal;
    }

    .btn {
        @apply rounded-l-none;
    }
}

.form__fields,
.form-builder,
.donation-form {
    @apply flex flex-col items-start gap-4;

    .form__header {
        @apply text-18;
    }

    fieldset {
        @apply w-full;
    }

    label {
        @apply block;
        @apply mb-1;
        @apply text-14 tracking-wide;
    }

    input[type=text],
    input[type=email],
    input[type=password],
    input[type=number] {
        @apply w-full;
    }

    input[type=submit] {
        @extend .btn;
    }

    > div {
        @apply w-full;
    }

    .boolean-container {
        @apply flex gap-2;

        input {
            @apply flex-shrink-0;
        }
    }

    .input-container {
        > div {
            @apply mt-4;
        }
    }

    div:has(> *[required=""]) {
    
        label:after {
            content: "";
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8px' height='7.55234702px' version='1.1' viewBox='0 0 8 7.55234702'%3E%3Cg id='Page-1' fill='none' fill-rule='evenodd' stroke='none' stroke-width='1'%3E%3Cg id='asterisk' fill='red' fill-rule='nonzero'%3E%3Cpath id='path3063' d='M0 3.558.557 1.84c1.283.452 2.215.843 2.796 1.174C3.199 1.554 3.118.549 3.11 0h1.754c-.024.799-.117 1.8-.278 3.002.831-.42 1.783-.807 2.856-1.162L8 3.558c-1.025.34-2.03.565-3.014.678.493.428 1.186 1.19 2.082 2.287l-1.452 1.03c-.468-.638-1.021-1.505-1.659-2.603-.596 1.138-1.12 2.005-1.573 2.602L.956 6.524c.936-1.154 1.606-1.917 2.01-2.288A43.417 43.417 0 0 1 0 3.558'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
    
            @apply inline-block;
            @apply h-2 w-2;
            @apply bg-no-repeat bg-contain;
            @apply flex-shrink-0;
            @apply -translate-y-1 translate-x-0.5;
        }
    }
}

.form__fields:has(.braintree-notifications) {
    @apply gap-0;

    .btn {
        @apply mt-4;
    }
}

@include min-width(lg) {
    .form__fields--two-col {
        @apply grid grid-cols-2 gap-x-8 !important;
    }
}

.required:not(.ts-wrapper) {
    @apply flex gap-1;

    &:after {
        content: "";
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8px' height='7.55234702px' version='1.1' viewBox='0 0 8 7.55234702'%3E%3Cg id='Page-1' fill='none' fill-rule='evenodd' stroke='none' stroke-width='1'%3E%3Cg id='asterisk' fill='red' fill-rule='nonzero'%3E%3Cpath id='path3063' d='M0 3.558.557 1.84c1.283.452 2.215.843 2.796 1.174C3.199 1.554 3.118.549 3.11 0h1.754c-.024.799-.117 1.8-.278 3.002.831-.42 1.783-.807 2.856-1.162L8 3.558c-1.025.34-2.03.565-3.014.678.493.428 1.186 1.19 2.082 2.287l-1.452 1.03c-.468-.638-1.021-1.505-1.659-2.603-.596 1.138-1.12 2.005-1.573 2.602L.956 6.524c.936-1.154 1.606-1.917 2.01-2.288A43.417 43.417 0 0 1 0 3.558'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");

        @apply inline-block;
        @apply h-2 w-2;
        @apply bg-no-repeat bg-contain;
        @apply flex-shrink-0;
    }
}

.required .required {
    @apply hidden;

    &:after {
        content: none;
    }
}

.form__mailing-list-preferences > div {
    @apply grid;
    @apply gap-y-4 gap-x-1;

    grid-template-columns: min-content auto;

    input[type="checkbox"] {
        top: 0px;
    }
}

/*------------------------------------*\
  #TOM-SELECT
\*------------------------------------*/

.ts-dropdown,
.ts-control,
.ts-control input {
    @apply text-16;
    @apply font-medium;
}

.ts-wrapper:not(.form-control):not(.form-select).single .ts-control {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right 0.75rem center;
    background-size: 16px 12px;
}

.ts-wrapper.single .ts-control > div {
    cursor: pointer;
    margin: 0 3px 3px 0;
    padding: 2px 6px;
    background: #f2f2f2;
    color: #303030;
    border: 0px solid #d0d0d0;
}

/*------------------------------------*\
  #SELECT2
\*------------------------------------*/

.no-tomselect {
    select {
        @apply w-full;
    }
}

.select2-hidden-accessible {
    @apply w-full;
}

.select2-container {
    
    @apply text-left;
}

.select2-selection {
    min-height: rem(38px);
}

.select2-selection__rendered {
    line-height: rem(38px) !important;
}

.select2-selection__arrow {
    height: rem(38px) !important;
}