html {
    @apply h-full;
    scroll-padding-top: $nav-height;
}

body {
    @apply flex flex-col;
    @apply overflow-x-hidden;

    padding-top: $nav-height;
    scroll-padding-top: $nav-height;
    min-height: 100%;
}

.global-wrapper {
    @apply flex-grow;
    @apply overflow-x-hidden;
}

.footer {
    @apply flex-shrink-0;
}

.full-width {
    @apply w-screen;
    @apply relative;
    @apply left-1/2;
    @apply transform -translate-x-1/2;
}

.contained-width .contained-width {
    @apply px-0;
}

.max-width {
    max-width: $max-width;
}

.max-text-width {
    max-width: $max-text-width;
}

@include min-width(lg) {
    .content-wrapper--with-sidebar {
        @apply grid grid-cols-12;

        .content {
            @apply col-span-9;
        }

        .sidebar {
            @apply col-span-3;
        }
    }
}

@include max-width(lg) {
    body {
        padding-top: $mobile-nav-height;
    }
}