.split-panel__content {
    @apply py-12;
}

.split-panel__descriptor {
    @apply font-bold tracking-wide;
    @apply mb-1;
}

.split-panel__title {
    @apply mb-6;
}

.split-panel {
    .split-panel__body * {
        @apply text-22;
        @apply font-bold;
    }
}

.split-panel__citation {
    @apply mt-4;
    
    * {
        @apply text-22;
    }
}

.split-panel__cta {
    @apply mt-4;

    a {
        @extend .link--cta;
    }
}

.split-panel__image {
    @apply relative;
}

.split-panel--image-on-right {
    .split-panel__image {
        @apply flex justify-end items-center;
        
    }
}

.split-panel {
    &.theme-white,
    &.theme-pink {
        .split-panel__title {
            @apply text-blue;
        }

        .cta {
            @apply text-blue;
    
            &:after {
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='4.5633px' height='7.60549px' version='1.1' viewBox='0 0 4.5633 7.60549'%3E%3Cdefs%3E%3Cpath id='path-n7xwcsb74j-1' d='m0 6.463 1.054 1.142 3.509-3.802L1.054 0 0 1.143l2.455 2.66z'/%3E%3C/defs%3E%3Cg id='Page-1' fill='none' fill-rule='evenodd' stroke='none' stroke-width='1'%3E%3Cmask id='mask-n7xwcsb74j-2' fill='%23fff'%3E%3Cuse xlink:href='%23path-n7xwcsb74j-1'/%3E%3C/mask%3E%3Cuse xlink:href='%23path-n7xwcsb74j-1' id='Path' fill='%23325BE7'/%3E%3C/g%3E%3C/svg%3E");
            }
        }
    }

    &.theme-blue {
        @apply text-white;

        .split-panel__title {
            @apply text-pink;
        }

        .cta {
            @apply text-pink;

            &:after {
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='4.5633px' height='7.60549px' version='1.1' viewBox='0 0 4.5633 7.60549'%3E%3Cdefs%3E%3Cpath id='path-n7xwcsb74j-1' d='m0 6.463 1.054 1.142 3.509-3.802L1.054 0 0 1.143l2.455 2.66z'/%3E%3C/defs%3E%3Cg id='Page-1' fill='none' fill-rule='evenodd' stroke='none' stroke-width='1'%3E%3Cmask id='mask-n7xwcsb74j-2' fill='%23FFF%0A'%3E%3Cuse xlink:href='%23path-n7xwcsb74j-1'/%3E%3C/mask%3E%3Cuse xlink:href='%23path-n7xwcsb74j-1' id='Path' fill='%23EEB4F5'/%3E%3C/g%3E%3C/svg%3E");
            }
        }
    }

    &.theme-beige {
        .split-panel__title {
            @apply text-orange;
        }

        .cta {
            @apply text-orange;

            &:after {
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='4.5633px' height='7.60549px' version='1.1' viewBox='0 0 4.5633 7.60549'%3E%3Cdefs%3E%3Cpath id='path-n7xwcsb74j-1' d='m0 6.463 1.054 1.142 3.509-3.802L1.054 0 0 1.143l2.455 2.66z'/%3E%3C/defs%3E%3Cg id='Page-1' fill='none' fill-rule='evenodd' stroke='none' stroke-width='1'%3E%3Cmask id='mask-n7xwcsb74j-2' fill='%23FFF%0A'%3E%3Cuse xlink:href='%23path-n7xwcsb74j-1'/%3E%3C/mask%3E%3Cuse xlink:href='%23path-n7xwcsb74j-1' id='Path' fill='%23BF5321'/%3E%3C/g%3E%3C/svg%3E");
            }
        }
    }

    &.theme-green-lime {
        .split-panel__title {
            @apply text-green-dark;
        } 
    }
}

@include min-width(lg) {
    .split-panel__content {
        @apply grid grid-cols-2 gap-0;
    }

    .split-panel__text {
        @apply flex flex-col items-start justify-center;
    }

    .split-panel__image {
        img {
            width: rem(620px);
        }
    }

    .split-panel--image-on-right {
        .split-panel__image {
            @apply order-2;

            left: rem(140px);
        }

        .split-panel__text {
            @apply order-1;
        }
    }
}

@include min-width(md) {
    .split-panel--image-on-right {
        .split-panel__image {
            left: rem(180px);
        }
    }

    .split-panel--image-on-left {
        .split-panel__image {
            left: rem(-140px);
        }
    }
}

@include max-width(lg) {
    .split-panel__content {
        @apply py-8;
    }

    .split-panel__image {
        img {
            width: rem(520px);
        }
    }

    .split-panel__content {
        @apply flex flex-col-reverse gap-8;
    }

    .split-panel--image-on-right {
        .split-panel__image {
            @apply flex justify-end items-center;
        }
    }
}

@include max-width(md) {
    .split-panel--image-on-right {
        .split-panel__image {
            left: rem(45px);
        }
    }

    .split-panel--image-on-left {
        .split-panel__image {
            left: rem(-45px);
        }
    }
}