.embed {
    @apply mt-8 mb-8;

    .embed__wrapper--responsive iframe {
        @apply m-auto;
        @apply w-full;
    }
}
  
.embed__wrapper--responsive {
    @apply relative;
    @apply overflow-hidden;
}

.embed__wrapper--responsive iframe {
    @apply absolute top-0 left-0;
    @apply w-full h-full;

    border: 0;
}

.embed__wrapper--unresponsive iframe {
    @apply m-auto;
}

.modal__content .embed-message {
    @apply p-4;
    @apply my-0 mx-auto;
    @apply text-center;
    @apply bg-white;

    max-width: 400px;
}

.html-embed {
    input[type=checkbox],
    input[type=radio] {
        all: revert !important;
    }

    .required {
        display: revert !important;
    }

    .required {
        &:after {
            content: none !important;
        }
    }
}