.hero {
    img {
        @apply w-full;
    }
}

.hero__content {
    @apply font-bold;

    .block-paragraph {
        @apply mt-4;
    }
}

.hero__title {
    @apply text-navy;
}

.hero--with-image {
    @apply relative;

    .hero__shape {
        @apply absolute;
    }
}

@include min-width(lg) {
    .hero__content {
        .block-paragraph,
        .rich-text {
            @apply mt-8;
            @apply text-22;
        }
    }

    .hero--with-image {
        .hero__content {
            @apply absolute;

            padding-left: rem(80px);
            padding-top: rem(80px);
        }

        .block-paragraph {
            max-width: rem(487px);
        }

        .hero__shape {
            @apply left-0 top-0;
        }
    }
}

@include max-width(lg) {
    .hero--with-image {
        .hero__content {
            @apply relative;
        }

        .hero__text {
            padding: rem(50px) rem(25px) 0 rem(25px);
            max-width: rem(700px);

            @apply relative z-20;
        }

        .hero__shape {
            @apply z-10;
            @apply w-full;
            @apply h-auto;
        }

        img {
            min-height: rem(300px);

            @apply object-cover;
        }
    }
}

@include between-widths(md, lg) {
    .hero--with-image {
        .hero__text {
            padding: rem(50px) rem(50px) 0 rem(50px);
        }
    }
}

/*------------------------------------*\
  #HERO-VARIANTS
\*------------------------------------*/

.hero--login {
    img {
        @apply object-cover;
        
        max-height: rem(572px);
    }
}

.hero {
    &.theme-red {
        .fill-will-change {
            @apply fill-red;
        }
    }

    &.theme-beige {
        .fill-will-change {
            @apply fill-beige;
        }
    }

    &.theme-green {
        .fill-will-change {
            @apply fill-green-mid;
        }
    }

    &.theme-blue {
        .block-paragraph,
        .hero__title {
            @apply text-white;
        }

        .fill-will-change {
            @apply fill-blue;
        }
    }

    &.theme-pink {
        .fill-will-change {
            @apply fill-pink;
        }
    }

    &.theme-green-lime {
        .fill-will-change {
            @apply fill-green-lime;
        }
    }

    &.theme-purple {
        .fill-will-change {
            @apply fill-purple;
        }
    }

    &.theme-beige {
        .fill-will-change {
            @apply fill-beige;
        }
    }

    &.theme-white {
        .fill-will-change {
            @apply fill-white;
        }
    }

    &.theme-amber {
        .block-paragraph {
            @apply text-black;
        }

        .fill-will-change {
            @apply fill-amber;
        }
    }
}

@include min-width(lg) {
    .hero--listening {
        .hero__content {
            max-width: rem(750px);
        }

        .hero__shape--listening-desktop {
            @apply w-auto;
        }
    
        .hero__shape--listening-mobile {
            @apply hidden;
        }
    }

    .hero--writing {
        .hero__content {
            max-width: rem(700px);
        }

        .hero__shape--writing-mobile {
            @apply hidden;
        }

        .hero__shape--writing-desktop {
            height: 101%;
        }
    }

    .hero--speaking {
        .hero__content {
            max-width: rem(700px);
        }

        .hero__shape--speaking-mobile {
            @apply hidden;
        }
    }

    .hero--reading {
        .hero__content {
            max-width: rem(700px);
        }

        .hero__shape--reading-desktop {
            max-height: 95%;
        }

        .hero__shape--reading-mobile {
            @apply hidden;
        }
    }
}

@include max-width(lg) {
    .hero--listening {
        .hero__shape--listening-desktop {
            @apply hidden;
        }
    
        .hero__shape--listening-mobile {
            @apply left-0 right-0 top-full;
            @apply w-full;
        }
    }

    .hero--writing {
        .hero__shape--writing-desktop {
            @apply hidden;
        }

        .hero__shape--writing-mobile {
            @apply w-full;
            @apply top-full;
        }
    }

    .hero--speaking {
        .hero__shape--speaking-desktop {
            @apply hidden;
        }

        .hero__shape--speaking-mobile {
            @apply w-full h-auto;
            @apply -translate-y-8;
        }
    }
    
    .hero--reading {
        .hero__shape--reading-desktop {
            @apply hidden;
        }

        .hero__shape--reading-mobile {
            @apply w-full;
            @apply top-full;
        }
    }
}

/*------------------------------------*\
  #HERO-SIMPLE
\*------------------------------------*/

.hero--simple {
    @apply py-12;

    .hero__title {
        @apply mb-4;
    }

    .block-paragraph,
    .rich-text {
        @apply mt-0;
    }
}

@include min-width(lg) {
    .hero--simple {
        @apply py-16;
    }
}

/*------------------------------------*\
  #HERO-RESOURCES
\*------------------------------------*/

.hero--resources {
    @apply bg-red;

    .search-form {
        @apply mt-6;
        @apply w-full;
    }

    input {
        @apply flex-grow;
    }
}