.account-management-page__signup-mailing-list #id_mailinglist_news_preferences_6290,
.account-management-page__signup-mailing-list label[for="id_mailinglist_news_preferences_6290"] {
    display: none;
}
.account-management-page__signup-mailing-list #id_mailinglist_news_preferences_6846,
.account-management-page__signup-mailing-list label[for="id_mailinglist_news_preferences_6846"] {
    display: none;
}

.account-management-page {
    @apply bg-grey-light;
}

.account-management-page__body {
    @apply bg-white;
    @apply text-center;
    @apply p-8;
    @apply mx-auto my-12;

    border: 1px solid #e4e4e4;
}

.account-management-page__body {
    form p {
        @apply my-4;
    }
}

.account-management-page__title {
    @apply text-30;
    @apply mb-3;
}

.account-management-page__subtitle {
    @apply font-medium;
}

.account-management-page__form {
    @apply mt-6;
    @apply text-left;

    .validation .btn {
        @apply mt-6;
    }

    /* div:has(input:required),
    div:has(select:required), {
        label {
            &:after {
                content: "*";

                @apply text-red;
                @apply ml-1;
            }
        }
    } */
}

.account-management-page__select-school {
    @apply text-left;
    @apply mt-6;

    > label {
        @apply block;
        @apply font-bold;
        @apply text-22;
        @apply mb-2;
    }
}

.account-management-page__button-wrapper {
    @apply text-center;
    @apply mt-6;

    .btn {
        @apply mb-3;
    }
}

label[for="id_remember"] {
    @apply inline-block;
}

.account-management-page__postcode-reveal {
    @apply mt-5;

    label {
        @extend .caption;
        @apply block;
        @apply mb-1;
    }
}

.account-management-page__signup-mailing-list {
    > div {
        @apply grid;
        @apply gap-y-4 gap-x-1;

        grid-template-columns: min-content auto;
    }

    input[type="checkbox"] {
        top: 0px;
    }
}

.account-management-page__login {
    @apply text-left;

    .account-management-page__title {
        @apply text-22;
        @apply text-navy;
        @apply mb-0;
    }

    .account-management-page__header .rich-text {
        @apply mt-4;
    }

    .account-management-page__form {
        @apply mt-4;
    }

    .link--cta {
        @apply text-blue;

        &:after {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='4.5633px' height='7.60549px' version='1.1' viewBox='0 0 4.5633 7.60549'%3E%3Cdefs%3E%3Cpath id='path-n7xwcsb74j-1' d='m0 6.463 1.054 1.142 3.509-3.802L1.054 0 0 1.143l2.455 2.66z'/%3E%3C/defs%3E%3Cg id='Page-1' fill='none' fill-rule='evenodd' stroke='none' stroke-width='1'%3E%3Cmask id='mask-n7xwcsb74j-2' fill='%23fff'%3E%3Cuse xlink:href='%23path-n7xwcsb74j-1'/%3E%3C/mask%3E%3Cuse xlink:href='%23path-n7xwcsb74j-1' id='Path' fill='%23325BE7'/%3E%3C/g%3E%3C/svg%3E");
        }
    }

    .bullet-list {
        @apply my-4;
    }

    .bullet-list__items {
        @apply flex flex-col gap-2;
    }

    .bullet-list__item {
        @apply flex gap-4 items-start;

        svg {
            width: rem(23px);
            height: auto;
        }
    }
}

.account-management-page__payment-types {
    @apply mt-8;

    li {
        @apply bg-white;
        @apply px-2 py-4;
        @apply flex flex-col;
    }

    p {
        @apply flex-grow;
    }
}

.billing-form .account-management-page__payment-types {
    li {
        @apply bg-grey-light;
        @apply p-6;
    }
}

@include max-width(md) {
    .account-management-page__payment-types {
        @apply grid-cols-1;

        button {
            width: rem(200px);

            @apply mx-auto;
        }

        li {
            @apply px-4;
        }
    }
}

@include min-width(lg) {
    .account-management-page__login {
        @apply grid grid-cols-2 gap-12;
    }
}

@include max-width(lg) {
    .account-management-page__login {
        @apply flex flex-col gap-8;
    }
}
