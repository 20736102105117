.resources-list {
    @apply my-10;
}

.resources-list__title {
    @apply py-2 px-4;
    @apply bg-navy text-white;
}

.resources-list__item {
    border-color: #D8D8D8;

    @apply border-b;
    @apply px-4 py-3;
}

.resources-list__link {
    @apply font-bold text-navy;
    @apply flex justify-between gap-4;
    @apply no-underline;

    &:hover, &:active, &:focus {
        .resources-list__link-title {
            @apply underline;
        }
    }
}

.resources-list__meta {
    @apply flex items-center gap-2;
}

.resources-list__file-type {
    @apply uppercase;
}

.resources-list__button-wrapper {
    @apply mt-2;
    @apply flex gap-4;
}

.resources-list__zip-bundle {
    @apply mb-6;
}

@include max-width(sm) {
    .resources-list__link {
        @apply flex flex-col items-start gap-2;
    }

    .resources-list__button-wrapper {
        @apply flex-col items-start;

        .btn {
            @apply w-full;
        }
    }
}