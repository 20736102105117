.step-tracker {
    @apply py-8;
    @apply bg-navy;
}

.step-tracker__content {
    @apply flex justify-center gap-8;
}

.step-tracker__step {
    @apply text-40;
    @apply font-bold;
    @apply rounded-full;
    @apply bg-white;
    @apply h-14 w-14;
    @apply flex justify-center items-center;
}

.step-tracker__step[aria-current="step"] {
    @apply text-blue;
}