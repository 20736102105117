.filters-header {
    border: 1px solid #DDDDDD;
    background-color: #F9F9F9;

    @apply border-b-0;
    @apply p-4;
    @apply w-full;
    @apply text-black;
}

.filters {
    border: 1px solid #DDDDDD;

    @apply bg-white;
}

.filters__fieldset {
    border-bottom: 1px solid #DDDDDD;
}

.filters__fieldset--search {
    @apply p-4;

    input {
        @apply w-full;
    }
}

.filters__fieldset-toggle {
    @apply flex justify-between items-center gap-4;
    @apply w-full;
    @apply p-4;
    @apply text-18 font-bold;
    @apply text-left;
    @apply text-navy;

    #Path {
        @apply fill-black;
        @apply opacity-20;
    }
}

.filters__fieldset-toggle[aria-expanded="true"] {
    .icon-arrow {
        @apply rotate-180;
    }
}

.filters__option-set {
    @apply p-4 pt-0;
    @apply hidden;
    @apply overflow-y-scroll;

    max-height: rem(300px);

    &.open {
        @apply block;
    }
}

.filters__option {
    @apply my-2;
    @apply flex items-center gap-2;

    input {
        @apply flex-shrink-0;

        top: 1px;
    }
}

.filters .btn {
    @apply mx-4 my-5;
}

@include max-width(lg) {
    .filters__toggle {
        border-bottom: 1px solid #DDDDDD;
    
        @apply text-left;
        @apply flex justify-between items-center;

        #Path {
            @apply fill-black;
            @apply opacity-20;
        }
    }

    .filters__toggle[aria-expanded="true"] {
        .icon-arrow {
            @apply rotate-180;
        }
    }

    .filters {
        @apply hidden;
        @apply border-t-0;
    }
}