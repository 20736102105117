.sidebar {
    background-color: #F7F7F8;

    @apply relative;
}

.sidebar:after {
    content: "";
    width: 15vw;
    background-color: #F7F7F8;

    @apply h-full;
    @apply absolute left-full top-0;
}

.sidebar > * {
    @apply relative z-10;
    @apply my-8;

    &:first-of-type {
        @apply mt-0;
    }
}

@include max-width(lg) {
    .sidebar {
        @apply py-4;
        @apply flex flex-col;
    }

    .sidebar:after {
        width: 200vw;
        left: -100vw;
        border-top: 1px solid #E1E1E1;

        @apply top-0 z-0;

    }
}

@include min-width(lg) {
    .sidebar {
        border-left: 1px solid #E1E1E1;

        @apply pl-4;
        @apply pt-13 pb-6;
    } 
}

/*------------------------------------*\
  #SIDEBAR-BLOCKS
\*------------------------------------*/

.feedback-form {
    border: 1px solid #E4E4E4;

    @apply bg-white;
    @apply p-4;

    label span {
        @apply font-bold text-navy;
        @apply block;
        @apply mb-2;
    }

    textarea,
    div {
        @apply max-w-full;
    }

    .btn {
        @apply mt-4;
    }

    .success-message {
        @apply font-bold text-navy;
    }
}

.link-list {
    .link-list__title {
        @apply text-navy;
        @apply mb-1;
    }

    .link-list__link {
        @apply font-bold text-blue;
    }

    .link-list__item {
        @apply border-t border-beige;
        @apply pt-2 mt-3;
    }

    .link-list__item-title {
        @apply font-bold text-navy;
        @apply block;
    }

    .link-list__header {
        @apply border-t-4 border-green-mid;
        @apply pt-3;
        @apply flex flex-col gap-1;
    }
}

.link-list--generic {
    .link-list__header {
        @apply border-pink;
    }
}

.account-summary-block {
    @apply bg-white;
    @apply p-4;

    border: 1px solid #E4E4E4;
}

.account-summary-block--mobile {
    @apply hidden;
    @apply mt-6;
}

@include max-width(lg) {
    .block-full_members_newsletter {
        @apply -mb-8;
        @apply mt-4 !important;
    }

    .account-summary-block--desktop {
        @apply hidden;
    }

    .account-summary-block--mobile {
        @apply block;
    }
}

@include min-width(lg) {
    .link-list--news {
        .link-list__header {
            @apply flex flex-row gap-1 justify-between items-center;
        }
    }

    .block-full_members_newsletter {
        @apply -mb-8;
    }
}