.image-banner-panel {
    @apply py-12;
    @apply bg-center bg-cover bg-no-repeat;
}

.image-banner-panel__content {
    @apply p-6;
}

.image-banner-panel__title {
    @apply mb-6;
    @apply text-blue;
}

.image-banner-panel__text {
    @apply flex flex-col items-start justify-center;
}

.image-banner-panel__image {
    img {
        width: rem(400px);
        filter: drop-shadow(0px 60px 50px rgba(0, 0, 0, 0.10));
    }
}

@include min-width(lg) {
    .image-banner-panel {
        @apply py-20;
    }

    .image-banner-panel__content {
        @apply grid grid-cols-2 gap-8;
        @apply p-12;
    }

    .image-banner-panel__image {
        @apply flex justify-end;
    }
}

@include max-width(lg) {
    .image-banner-panel__content {
        @apply flex flex-col items-center;
    }

    .image-banner-panel__text {
        @apply text-center;

        .btn {
            @apply mx-auto;
        }
    }

    .image-banner-panel__image {
        @apply mt-8;
    }
}

@include max-width(sm) {
    .image-banner-panel__title {
        @apply text-22;
    }
}