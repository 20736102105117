.btn {
    @apply inline-block;
    @apply text-center;
    @apply no-underline;
    @apply bg-green-mid text-black;
    @apply font-bold;
    @apply rounded-md;
    @apply text-17;
    @apply px-6 py-2;
    @apply transition-colors duration-100;

    &:hover, &:focus, &:active {
        @apply bg-blue text-white;
    }
}

.btn--navy {
    @apply bg-navy text-white;
}

.btn--grey {
    @apply bg-grey-mid;
}

.btn--blue {
    @apply bg-blue text-white;

    &:hover, &:focus, &:active {
        @apply bg-navy;
    }
}

.btn--locked {
    &:after {
        content: "";
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='1em' viewBox='0 0 448 512'%3E%3Cpath fill='%23FFFFFF' d='M144 144v48h160v-48c0-44.2-35.8-80-80-80s-80 35.8-80 80zm-64 48v-48C80 64.5 144.5 0 224 0s144 64.5 144 144v48h16c35.3 0 64 28.7 64 64v192c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V256c0-35.3 28.7-64 64-64h16z'/%3E%3C/svg%3E");
        height: 17px;
        width: 15px;
        top: 1px;

        @apply inline-block;
        @apply bg-no-repeat bg-contain bg-center;
        @apply ml-2;
        @apply relative;
    }
}

.btn--download {
    @apply inline-flex items-center;

    svg {
        @apply flex-shrink-0;
        @apply ml-2;
    }
}