.landing-page {
    .content-wrapper {
        @extend .contained-width;
    }

    .content {
        @apply my-8; 
    }
}

.landing-page__body-content {
    > .rich-text {
        border-bottom: 1px solid #DDD;
        
        @apply my-8;
    }
}

@include min-width(lg) {
    .content-wrapper--with-sidebar {
        .landing-page__body-content {
            @apply pr-20;
        }
    }
}