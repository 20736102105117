/*------------------------------------*\
  #BREAKPOINT-MIXINS
\*------------------------------------*/

@mixin min-width($breakpoint) {
    $breakpoint-value: map-get($breakpoints, $breakpoint);

    @media (min-width: $breakpoint-value) {
        @content;
    }
}

@mixin max-width($breakpoint) {
    $breakpoint-value: map-get($breakpoints, $breakpoint);

    @media (max-width: $breakpoint-value) {
        @content;
    }
}

@mixin between-widths($lower, $upper) {
    $lower-breakpoint: map-get($breakpoints, $lower);
    $upper-breakpoint: map-get($breakpoints, $upper);

    @media (min-width: $lower-breakpoint) and (max-width: $upper-breakpoint) {
        @content;
    }
}

/*------------------------------------*\
  #TAILWIND-MIXINS
\*------------------------------------*/

@layer utilities {
    .contained-width {
        @apply mx-auto;

        max-width: $max-width;
        padding-left: rem(25px);
	    padding-right: rem(25px);

        @media screen(md) {
            padding-left: rem(100px);
            padding-right: rem(100px);
        }
    }
}