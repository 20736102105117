.card {
    .eyebrow {
        @apply my-2;
    }

    a.card__title {
        @apply no-underline;

        &:hover, &:focus, &:active {
            @apply underline;
        }
    }
}

.card--generic {
    @apply flex flex-col items-start;

    a {
        @apply w-full;
    }

    .btn {
        @apply mt-4;
        @apply w-auto;
    }

    img {
        @apply w-full;
    }

    .rich-text {
        @extend .max-text-width;
        
        @apply mt-2;
    }

    .card__text {
        @apply p-5;
        @apply h-full w-full;
        @apply flex-shrink;
    }

    .card__category {
        @apply font-medium;
        @apply mt-1;
    }
}

.card--news {
    @apply bg-white;
    @apply border border-grey-mid;

    .card__content {
        @apply p-5;
    }

    .card__image-link {
        @apply mb-4;
        @apply block;
    }

    .card__text {
        @apply bg-white !important;
    }

    .rich-text {
        @apply my-3;
    }
}

.card--sidebar {
    @apply bg-green-mid;

    .card__title {
        @apply text-black;
    }

    .card__text {
        @apply p-4;
    }

    .rich-text {
        @apply mt-4 mb-2;
    }

    .link--cta,
    .link {
        box-shadow: 0px 1px 0px 0px #D8D8D8;

        @apply bg-white;
        @apply px-4 pt-2 pb-3;
        @apply w-full;
        @apply flex;
    }

    .link {
        @extend .link--cta;
    }
}

.card--sidebar .card__text {
    &.theme-navy,
    &.theme-green-dark,
    &.theme-orange,
    &.theme-amber,
    &.theme-blue {
        .card__title {
            @apply text-white;
        }
    }
}

/*------------------------------------*\
  #CARD-PERSON
\*------------------------------------*/

.card--person {
    a {
        @apply mt-1;
        @apply inline-block;
        @apply font-bold text-navy;
        @apply break-all;
    }
}

.card__job-title {
    @apply text-blue font-bold;
    @apply mt-1 mb-2;
}

/*------------------------------------*\
  #CARD-COLOURS
\*------------------------------------*/

.card--news {
    .card__content {
        @apply border-t-4 border-t-green-mid;
    }
}

.card--resource-page,
.card--research-page {
    .card__content {
        @apply border-t-4 border-t-blue;
    }
}

.card--programme,
.card--event-page {
    .card__content {
        @apply border-t-4 border-t-green-lime;
    }
}

.card--success-story {
    .card__content {
        @apply border-t-4 border-t-purple;
    }
}

.card--premium {
    .card__content {
        @apply border-t-4 border-t-red;
    }
}