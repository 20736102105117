.competition-page {
    .article-page__body-content > .rich-text {
        h2 {
            border-color: #DDD;

            @apply border-t;
            @apply pt-6 mt-8;
        }
    }
}

.competition-page__form {
    @apply mt-4;

    .btn {
        @apply mt-6;
    }
}