.cookies-banner {
    @apply fixed z-40;
    @apply bottom-0;
    @apply bg-white;
    @apply py-4;
    @apply border border-grey-mid border-b-0;
    @apply left-1/2;
    @apply -translate-x-1/2;
    
    width: 90%;
}

.cookies-banner {
    p {
        @apply mb-1;
    }
}

.cookies-banner__link {
    @apply text-navy;
    @apply font-bold;
}

@include min-width(sm) {
    .cookies-banner {
        a {
            @apply inline-flex;
        }

        button {
            @apply mt-4 mr-4;
        }
    }
}

@include max-width(sm) {
    .cookies-banner__options {
        @apply mt-4;
        @apply flex flex-col items-start gap-3;
    }
}