.masonry-block {
    @apply my-12;
}

.masonry-block__header {
    @apply mb-8;
}

.home-page {
    .masonry-block__title {
        @apply text-blue;
    }
}