.breadcrumbs {
    @apply border-b-4 border-green-mid;
    @apply pb-4 mb-6;
}

.breadcrumbs__items {
    @apply flex items-end flex-wrap gap-1;
}

.resource-page {
    .breadcrumbs {
        @apply border-pink;
    }
}

.generic-page {
    .breadcrumbs {
        @apply border-b-0;
        @apply pb-0;
    }
}