.listings-page {
    .content-wrapper {
        @apply bg-grey-light;
        @apply py-8;
    }
}

.listings-page__content {
    @apply my-8;
}

.listings-page__header {
    @apply text-black;
}

.listings-page__body--full-width {
    @apply col-span-12;
}

@include min-width(lg) {
    .listings-page__content {
        @apply grid grid-cols-12 gap-8;
    }

    .listings-page__filters {
        @apply col-span-3;
    }

    .listings-page__body {
        @apply col-span-9;
    }
}

    