.combo-panel {
    @apply my-12;
}

.combo-panel__item + .combo-panel__item {
    @apply mt-8;
}

.combo-panel__title {
    @apply text-blue;
    @apply mb-4;
}

.combo-panel__descriptor {
    @apply font-bold tracking-wide;
    @apply mb-1;
}

.combo-panel__cta {
    @apply mt-4;

    a {
        @extend .link--cta;

        @apply text-blue;

        &:after {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='4.5633px' height='7.60549px' version='1.1' viewBox='0 0 4.5633 7.60549'%3E%3Cdefs%3E%3Cpath id='path-n7xwcsb74j-1' d='m0 6.463 1.054 1.142 3.509-3.802L1.054 0 0 1.143l2.455 2.66z'/%3E%3C/defs%3E%3Cg id='Page-1' fill='none' fill-rule='evenodd' stroke='none' stroke-width='1'%3E%3Cmask id='mask-n7xwcsb74j-2' fill='%23fff'%3E%3Cuse xlink:href='%23path-n7xwcsb74j-1'/%3E%3C/mask%3E%3Cuse xlink:href='%23path-n7xwcsb74j-1' id='Path' fill='%23325BE7'/%3E%3C/g%3E%3C/svg%3E");
        }
    }
}

@include min-width(lg) {
    .combo-panel__item {
        @apply grid grid-cols-2 gap-12;
    }

    .combo-panel__text {
        @apply flex flex-col justify-center items-start;
    }

    .combo-panel__item:nth-of-type(even) {
        .combo-panel__text {
            @apply order-1;
        }

        .combo-panel__image {
            @apply order-2;
            @apply flex items-center justify-end;

            img {
                max-width: rem(470px);
            }
        }
    }
}

@include max-width(lg) {
    .combo-panel {
        @apply my-8;
    }

    .combo-panel__image {
        @apply mb-4;
    }
}

@include between-widths(sm, lg) {
    .combo-panel__item:nth-of-type(even) {
        .combo-panel__text {
            @apply text-right;
            @apply flex justify-end;
        }

        .combo-panel__image {
            @apply flex items-center justify-end;
        }

        .max-text-width {
            @apply flex flex-col items-end;
        }
    }
}