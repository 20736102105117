.bullet-list {
    @apply my-12;
}

.bullet-list__header {
    @apply mb-8;

    .rich-text {
        @apply mt-4;
    }
}

.bullet-list__item-title {
    @apply mt-3;
    @apply mb-2;
    @apply text-navy;
}

.bullet-list__cta-container {
    @apply mt-6;

    .cta {
        @extend .link--cta;

        @apply text-blue;

        &:after {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='4.5633px' height='7.60549px' version='1.1' viewBox='0 0 4.5633 7.60549'%3E%3Cdefs%3E%3Cpath id='path-n7xwcsb74j-1' d='m0 6.463 1.054 1.142 3.509-3.802L1.054 0 0 1.143l2.455 2.66z'/%3E%3C/defs%3E%3Cg id='Page-1' fill='none' fill-rule='evenodd' stroke='none' stroke-width='1'%3E%3Cmask id='mask-n7xwcsb74j-2' fill='%23fff'%3E%3Cuse xlink:href='%23path-n7xwcsb74j-1'/%3E%3C/mask%3E%3Cuse xlink:href='%23path-n7xwcsb74j-1' id='Path' fill='%23325BE7'/%3E%3C/g%3E%3C/svg%3E");
        }
    }
}

.bullet-list__disk {
    @apply h-5 w-5;
    @apply rounded-full;
}

.bullet-list__item--disk-bullet {
    @apply flex gap-4;

    .bullet-list__disk {
        @apply flex-shrink-0;
        @apply translate-y-1;
    }

    .bullet-list__item-title {
        @apply mt-0;
    }
}

@include min-width(lg) {
    .bullet-list__items {
        @apply gap-10;
    }
}

@include max-width(lg) {
    .bullet-list {
        @apply my-8;
    }

    .bullet-list__items {
        @apply grid-cols-1;
    }
}

.bullet-list__item--red {
    .fill-will-change {
        @apply fill-red;
    }
}

.bullet-list__item--blue {
    .fill-will-change {
        @apply fill-blue;
    }
}

.bullet-list__item--pink {
    .fill-will-change {
        @apply fill-pink;
    }
}

.bullet-list__item--purple {
    .fill-will-change {
        @apply fill-purple;
    }
}

.bullet-list__item--amber {
    .fill-will-change {
        @apply fill-amber;
    }
}