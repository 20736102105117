.mask-container--speaking {
    img {
        clip-path: url('#clip-path--speaking');
    }
}
.mask-container--listening {
    img {
        clip-path: url('#clip-path--listening');
    }
}
.mask-container--reading {
    img {
        clip-path: url('#clip-path--reading');
    }
}
.mask-container--writing {
    img {
        clip-path: url('#clip-path--writing');
    }
}