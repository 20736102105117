.account-page {
    .content-wrapper {
        @extend .contained-width;
    }

    h2, .h2 {
        @apply text-navy;
    }
}

.account-page__navigation {
    border-bottom: 1px solid #E1E1E1;

    .account-page__navigation-items {
        @apply flex;
        @apply overflow-x-auto;
        @apply max-w-full;
    }

    a {
        @apply block;
        @apply py-2 pr-4;
        @apply font-bold text-navy;
        @apply reverse-underline;
    }

    li {
        @apply my-2;
        @apply flex-shrink-0;

        &:not(:first-of-type) {
            border-left: 1px solid #EAEAEA;

            a {
                @apply pl-4;
            }
        }
    }
}

.account-page__navigation-item--active {
    @apply text-blue !important;
}

.account-page__header {
    @apply mt-8 pb-4 mb-4;
    border-bottom: 1px solid #E1E1E1;

    h1 {
        @apply mb-2;
        @apply text-40 text-navy;
    }

    p {
        @apply text-18;
    }

    .search-form {
        input {
            @apply flex-grow;
        }
    }
}

.account-page__settings-navigation {
    @apply -mt-2;
    @apply text-navy font-bold;

    ul {
        @apply flex gap-6;
        @apply max-w-full overflow-x-auto;
        @apply py-5;
    }

    li {
        @apply flex-shrink-0;
    }
}

.account-page__settings-link--active {
    @apply text-blue;
}

.account-page__form-fields {
    @apply flex flex-col gap-4;

    label {
        @apply block;
        @apply mb-1;
        @apply text-14 tracking-wide;
    }

    input[type=text],
    input[type=email] {
        @apply w-full;
    }
}

.account-page__user {
    @apply border-b border-grey-light;
    @apply py-3;

    .btn {
        @apply py-1 px-4;
        @apply mt-2;
    }
}

.account-page__user--primary {
    @apply border-grey-mid;
}

@include max-width(lg) {
    .account-page {
        .content-wrapper {
            max-width: 100%;
        }
    }

    .account-page__activities-grid {
        @apply grid-cols-1;
    }
}

@include min-width(lg) {
    @include min-width(lg) {
        .content-wrapper--with-sidebar {
            .account-page__body-content {
                @apply pr-20;
            }
        }
    }
}