.modal {
    @apply hidden;
}
  
.modal.is-open {
    @apply block;
}

.modal {
    .contained-width {
        @apply w-full;
    }
}

.modal__overlay {
    @apply fixed top-0 left-0 right-0 bottom-0 z-40;
    @apply flex justify-center items-center;
    @apply overflow-y-auto;
    @apply p-6;

    background: rgba(0,0,0,0.6);
    height: 100vh;
}

.modal__container {
    @apply bg-white;
    @apply rounded-lg;
    @apply p-10;
    @apply overflow-y-auto;

    max-height: 90vh;
}

.modal__button-container {
    @apply flex justify-end;

    svg {
        max-width: rem(20px);
        @apply h-auto;
    }
}

.modal__dismiss {
    @apply text-14;
    @apply underline;

    &:hover, &:focus, &:active {
        @apply no-underline;
    }
}

@include max-width(sm) {
    .modal__container {
        @apply p-6;
    }

    .modal__button-container {
        @apply mb-4
    }
}

.modal--school-info {
    @apply text-center;
}

.phase-info__submit {
    margin-top: 110px;
}
