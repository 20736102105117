.navigation {
    @apply fixed top-0 z-30;
    @apply w-full;
    @apply bg-white text-grey-dark;

    height: $nav-height;
    border-bottom: 1px solid #DADADB;
}

.navigation__masthead {
    @apply flex items-center;
}

.navigation__search {
    &:hover, &:focus, &:active {
        #Shape {
            @apply fill-green-mid;
        }
    }
}

.logo-wfl {
    @apply mb-2;

    max-width: rem(200px);
}

.navigation__account {
    @apply font-bold;
    @apply text-navy;

    span {
        @apply text-green-mid;
        @apply mx-1;
    }
}

.navigation__level-two-header {
    @apply text-blue;
    @apply font-bold;
    @apply tracking-wide;
    @apply mb-3;
    @apply text-16;
}

.navigation__level-two-list {
    @apply flex flex-col gap-2;

    a {
        @extend .reverse-underline;
    }
}

.navigation__level-one-toggle {
    @apply flex items-center gap-2;

    .icon-arrow {
        @apply inline-block;
        @apply relative;

        top: 2px;
    }
}

.navigation__latest-link {
    @apply no-underline;
    @apply block;
    @apply mb-2;

    h4 {
        @apply mt-1;
    }

    &:hover, &:focus, &:active {
        h4 {
            @apply underline;
        }
    }
}

.navigation .link--cta {
    @apply text-blue;
    @apply mt-2;

    &:after {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='4.5633px' height='7.60549px' version='1.1' viewBox='0 0 4.5633 7.60549'%3E%3Cdefs%3E%3Cpath id='path-n7xwcsb74j-1' d='m0 6.463 1.054 1.142 3.509-3.802L1.054 0 0 1.143l2.455 2.66z'/%3E%3C/defs%3E%3Cg id='Page-1' fill='none' fill-rule='evenodd' stroke='none' stroke-width='1'%3E%3Cmask id='mask-n7xwcsb74j-2' fill='%23fff'%3E%3Cuse xlink:href='%23path-n7xwcsb74j-1'/%3E%3C/mask%3E%3Cuse xlink:href='%23path-n7xwcsb74j-1' id='Path' fill='%23325BE7'/%3E%3C/g%3E%3C/svg%3E");
    }
}

@include min-width(lg) {
    .navigation {
        @apply px-10;
    }

    .navigation__content {
        @apply flex justify-between items-stretch;
        @apply relative;

        min-height: $nav-height;
    }

    .navigation__links-content {
        @apply flex flex-col items-end justify-stretch;
        @apply h-full;
    }

    .navigation__upper-links,
    .navigation__lower-links {
        @apply h-1/2;
        @apply flex gap-6;
    }

    .navigation__upper-links {
        @apply items-center;
    }

    .navigation__lower-links {
        @apply items-stretch;
    }

    .navigation__level-one-item {
        @apply h-full;

        .icon-triangle {
            @apply relative z-30;
            @apply left-1/2 -translate-x-1/2;
            @apply -translate-y-full;
            @apply hidden;

            top: 2px;
        }
    }

    .navigation__level-one-item--link {
        @apply flex items-center;
    }

    .navigation__level-one-item--single {
        @apply relative;
    }

    .navigation__level-one-toggle {
        @apply h-full;
    }

    .navigation__level-two {
        @apply absolute top-full left-0;
        @apply w-full;
        @apply bg-white;
        @apply p-8;

        border: 1px solid #DADADB;
    }

    .navigation__level-two.open {
        @apply z-20;
    }

    .navigation__level-two--multi {
        @apply grid grid-cols-10 gap-4;
    }

    .navigation__level-two--single {
        width: 200%;
        @apply px-4;

        .navigation__level-two-item {
            @apply p-0;
        }
    }

    .navigation__level-two--resources {
        .navigation__level-two-item {
            @apply col-span-3;
        }

        .navigation__level-two-item--resources-search {
            @apply col-span-4;
        }
    }

    .navigation__level-two-item {
        @apply px-4;
        @apply col-span-2;

        &:not(:last-of-type) {
            border-right: 1px solid #EAEAEA;
        }
    }
}

@include max-width(lg) {
    .navigation {
        .icon-triangle {
            @apply hidden;
        } 

        .logo-nlt {
            width: rem(100px);

            @apply h-auto;
        }
    }

    .navigation,
    .navigation__content {
        height: $mobile-nav-height;
    }

    .navigation__masthead {
        @apply justify-between;

        padding-left: rem(25px);
        padding-right: rem(25px);
    }

    .navigation__mobile-icons {
        @apply flex items-center gap-6;
    }

    .navigation__toggle[aria-expanded="true"] {
        .icon-hamburger {
            @apply hidden;
        }

        .icon-cross {
            @apply block;
        }
    }

    .navigation__toggle[aria-expanded="false"] {
        .icon-hamburger {
            @apply block;
        }

        .icon-cross {
            @apply hidden;
        }
    }

    .navigation__links {
        @apply absolute left-0;
        @apply w-full;
        @apply bg-white;
        @apply overflow-y-auto overflow-x-hidden;
        @apply py-8;

        top: $mobile-nav-height;
        height: calc(100vh - $mobile-nav-height);
        padding-left: rem(25px);
        padding-right: rem(25px);
    }

    .navigation__links-content {
        @apply flex flex-col gap-4;
    }

    .navigation__upper-links {
        @apply flex flex-col-reverse gap-6;
        @apply order-2;
    }

    .navigation__lower-links {
        @apply order-1;
        @apply flex flex-col gap-4;
    }
    
    .navigation__level-one-toggle[aria-expanded="true"] {
        .icon-arrow {
            @apply rotate-180;
        }
    }

    .navigation__level-two {
        @apply pl-4 pt-4;
        @apply flex flex-col gap-4;
    }

    .navigation__level-two-header {
        @apply mb-2;
    }

    .navigation__level-two-item {
        @apply pb-4;

        border-bottom: 1px solid #EAEAEA;
    }
}

/*------------------------------------*\
  #NAVIGATION-FUNCTIONALITY
\*------------------------------------*/

.navigation__level-two {
    @apply hidden;
}

@include min-width(lg) {
    .navigation__level-one-item {
        &:hover, &:focus-within {
            .navigation__level-two {
                @apply grid;
            }

            .navigation__level-one-toggle {
                @apply underline;
            }

            .icon-arrow {
                @apply rotate-180;
            }

            .icon-triangle {
                @apply block;
            }
        }
    }
}

@include max-width(lg) {
    .navigation__links {
        @apply hidden;
    }

    .navigation__level-two.open {
        @apply flex;
    }
}