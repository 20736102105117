.event-page {
    .content {
        @apply mx-auto;
    }
}

.event-page__info {
    border-bottom: 1px solid #DDD;

    @apply mb-6;
    @apply pb-6;

    h2 {
        @apply text-black text-22;
    }

    h3 {
        @apply text-18;
        @apply mt-1;
        @apply font-medium;
    }

    address {
        @apply mt-2;
    }

    .btn {
        @apply mt-4;
    }
}