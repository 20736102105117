.rich-text, .block-rich_text {
    > *:first-child {
        @apply my-0;
    }

    p {
        @apply my-4;
    }

    ul {
        @apply my-4 ml-8 list-disc;
    }

    ol {
        @apply my-4 ml-8 list-decimal;
    }

    li {
        @apply my-4;
    }

    h2 {
        @apply text-30;
        @apply text-navy;
    }

    h2 {
        @apply mb-4 mt-8;
    }

    h3, h4, h5, h6 {
        @apply mb-4 mt-6;
    }

    img {
        @apply my-8;

        &.right {
            @apply ml-auto;
        }
    }

    a {
        @apply font-bold;
        @apply text-navy;
    }
}