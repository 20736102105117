.wForm {
    .inputWrapper {
        @apply w-full;
    }

    .ts-wrapper.single .ts-control > div { 
        margin: 0 !important;
    }

    .ts-control input {
        padding: 3px !important;
    }

    input[type="radio"],
    input[type="checkbox"] {
        appearance: auto !important;

        @apply h-auto w-auto !important;
        @apply static !important;

        &:before, &:after {
            content: none;
        }
    }

    .required {
        &:after {
            content: none;
        }
    }
}