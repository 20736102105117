.card-group {
    @apply my-12;
}

.card-group__header {
    @apply mb-8;
}

.card-group__items {
    @apply mt-6;
}

.card-group__button-wrapper {
    @apply text-center;
    @apply mt-8;
}

.card-group__header {
    .rich-text {
        @apply mt-4;
    }
}

.card-group,
.card-group--sidebar {
    .card:nth-of-type(3n+1) {
        .card__text {
            @apply bg-purple;
        }
    }

    .card:nth-of-type(3n+2) {
        .card__text {
            @apply bg-green-lime;
        }
    }

    .card:nth-of-type(3n+3) {
        .card__text {
            @apply bg-pink;
        }
    }
}

.card-group--article-index {
    .card {
        .card__text {
            @apply bg-beige !important;
        }
    }

    .card-group__separator {
        @apply bg-green-lime;
        @apply p-3;
    }

    .card-group__button-wrapper .btn {
        @extend .btn--blue;
    }
}

@include max-width(lg) {
    .card-group__items {
        @apply grid-cols-1;
    }
}

.home-page {
    .card-group__title {
        @apply text-blue;
    }

    .card-group__header {
        .rich-text {
            @apply text-18;
            @apply font-bold;
        }
    }
}

@include min-width(lg) {
    .home-page {
        .card-group__header {
            .rich-text {
                @apply text-22;
            }
        }
    }
}

/*------------------------------------*\
  #SIDEBAR-CARD-GROUP
\*------------------------------------*/

.card-group--sidebar {
    .card-group__title {
        @apply text-navy;
    }

    .card-group__items {
        @apply flex flex-col gap-8;
    }
}

@include max-width(lg) {
    .card-group--sidebar {
        .rich-text {
            @extend .max-text-width;
        }
    }
}