.template-account-invitation {

    form {
        @apply flex flex-col gap-4;
        @apply my-4;
    }

    @include min-width(lg) {
        .two-col {
            @apply grid grid-cols-2 gap-x-8 gap-y-4;
        }
    }

    input[type=email], input[type=number], input[type=password], input[type=text], textarea, select {
        @apply w-full;
    }

    select {
        border: 1px solid #DDDDDD;
        @apply rounded-sm;
        @apply p-2;
    }

    fieldset {
        @apply flex flex-col gap-2;
    }

    .btn {
        width: fit-content;
    }

    input[type=checkbox]+label {
        @apply mr-4;
    }
}